import { Injectable, inject } from '@angular/core'
import { Observable, from, map, of } from 'rxjs'
import { Message } from '../enums/message.enum'
import { Device } from '../models/Device'
import { AppStateService } from '../store/state/app/app.state.service'
import { NotificationService } from './notification.service'

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  #notificationService = inject(NotificationService)
  #appStateService = inject(AppStateService)

  async initDevice() {
    return await Promise.all([this.getGeoCoordinates()])
      .then(([position]) => {
        if (position instanceof GeolocationPositionError) {
          this.#notificationService.showError(Message.ErrorMessageLocationAccess)
          return
        }
        const device = this.#constructDevice(position as unknown as GeolocationPosition)
        this.#appStateService.setDevice(device)
      })
  }

  #constructDevice(position: GeolocationPosition): Device {
    const platformInfo = this.getPlatformInfo()
    const deviceType = this.getDeviceType()
    const browserInfo = this.getBrowserInfo()

    return {
      platform: platformInfo,
      deviceType: deviceType,
      browserName: browserInfo.name,
      latitude: position.coords.latitude.toString(),
      longitude: position.coords.longitude.toString()
    }
  }

  getGeoCoordinates(): Promise<GeolocationPosition | GeolocationPositionError> {
    return new Promise((resolve) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve(position)
        },
        (error) => {
          resolve(error)
        }
      )
    })
  }

  getHashForDeviceId(uuid: string, email: string): Observable<string> {
    if (localStorage.getItem(email)) {
      return of(localStorage.getItem(email))
    }
    const encoder = new TextEncoder()
    const data = encoder.encode(uuid + email)
    return from(crypto.subtle.digest('SHA-256', data)).pipe(map((hashBuffer) => {
      const hashArray = Array.from(new Uint8Array(hashBuffer))
      const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('')
      localStorage.setItem(email, hashHex)
      return hashHex
    }))
  }

  getPlatformInfo() {
    const ua = navigator.userAgent
    if (/Macintosh/.test(ua)) return 'MacOS'
    if (/Windows/.test(ua)) return 'Windows'
    if (/Android/.test(ua)) return 'Android'
    if (/Linux/.test(ua)) return 'Linux'
    if (/iPhone|iPad|iPod/.test(ua)) return 'iOS'
    return 'Unknown'
  }

  getDeviceType(): 'Tablet' | 'Mobile' | 'Web' {
    const ua = navigator.userAgent
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return 'Tablet'
    }
    if (/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|NetFront|Silk-Accelerated|(hpw|web)OS|Fennec|Minimo|Opera M(obi|ini)|Blazer|Dolfin|Dolphin|Skyfire|Zune/.test(ua)) {
      return 'Mobile'
    }
    return 'Web'
  }

  getBrowserInfo() {
    const ua = navigator.userAgent
    let tem
    let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || []
      return { name: 'IE', version: (tem[1] || '') }
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\b(OPR|Edge)\/(\d+)/)
      if (tem != null) return { name: tem[1], version: tem[2] }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?']
    if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1])
    return {
      name: M[0],
      version: M[1]
    }
  }
}