import { Component, Input } from '@angular/core'
// import { InputSwitchChangeEvent } from 'primeng/inputswitch'
// import { UserStateService } from '../../../store/state/user/user.state.service'

@Component({
  selector: 'app-unauthorized-layout',
  templateUrl: './unauthorized-layout.component.html',
  styleUrl: './unauthorized-layout.component.scss'
})
export class UnauthorizedLayoutComponent {

  src_url = '../../../../assets/terms-privacy.pdf'

  privacyTerms = false

  //  userStateService: UserStateService = inject(UserStateService)

  // onUserSwitch(event: InputSwitchChangeEvent) {
  //   this.userStateService.setUserAuthorized(event.checked)
  // }

  showPivacyTerms() {
    this.privacyTerms = true
  }
}


@Component({
  selector: 'app-unauthorized-header-layout',
  styleUrl: './unauthorized-layout.component.scss',
  template: `<div class="flex justify-content-center">
  <div class="w-full text-center">
      <h1 [class]="headerCustomClass" [innerHTML]="header"></h1>
      <h2 [ngClass]="subHeaderCustomClass" *ngIf="subHeader && subHeader.length > 0"><span [innerHTML]="subHeader"></span></h2>
      <h4 class="mb-0 text-lg font-light" *ngIf="subHeader2 && subHeader2.length > 0" [innerHTML]="subHeader2"></h4>
  </div>
</div>`
})
export class UnauthorizedHeaderComponent {
  @Input() headerCustomClass: string = '';
  @Input() subHeaderCustomClass: string = 'semi-bold';
  @Input() header: string = '';
  @Input() subHeader: string = ''
  @Input() subHeader2: string = ''

}
