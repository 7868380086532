import { MenuItem } from 'primeng/api'
import { AppMenu } from '../menu/app.menu'
import { RolesService } from './roles.service'
import { Injectable, inject } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  rolesService: RolesService = inject(RolesService)
  private appMenu = AppMenu

  getMenu() {
    this.getMenuByRoles();
    return this.appMenu;
  }

  getDefaultMenu() {
    const result: MenuItem[] = [];
    result.push(this.appMenu.find(x => x.label == 'Support'));
    result.push(this.appMenu.find(x => x.label == 'Settings'));
    return result;
  }

  getMenuByRoles() {
    // TODO: get the roles from the rolesService and use them to filter the menu items
    const userModules = this.rolesService.getUserModules();
    for (let index = 0; index < this.appMenu.length; index++) {
      const element = this.appMenu[index];
      this.hasAccess(userModules, element);
    }
  }

  hasAccess(userModules: string[], menu: MenuItem): boolean {
    let hasMenuAccess = false;
    if (menu) {
      if (menu.state && menu.state.userModules && (menu.state.userModules as string[]).length > 0) {
        if (userModules) {
          userModules.forEach(element => {
            const access = (menu.state.userModules as string[]).find(x => x.toLocaleLowerCase() === element.toLocaleLowerCase());
            if (access) {
              hasMenuAccess = true;
              return;
            }
          });
        }
        if (!hasMenuAccess) {
          menu.visible = false;
        } else {
          menu.visible = true;
        }
        return hasMenuAccess;
      } else {
        if (menu.items) {
          for (let index = 0; index < menu.items.length; index++) {
            const element = menu.items[index];
            this.hasAccess(userModules, element);
          }
        }
        menu.visible = true;
      }
    }
    return true;
  }
}
