import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'

export const EVENT_TYPE = {
    LOGOUT: 'LOGOUT',
    PROFILE: 'PROFILEINFO',
    MENU_COLLAPSED: 'MENU_COLLAPSED'

}

@Injectable({
    providedIn: 'root'
})
export class AppEventService {
    private eventSubject = new Subject<string>()
    private eventDataSubject = new Subject<{ event: string, data: unknown }>()
    event$ = this.eventSubject.asObservable()
    dataEvent$ = this.eventDataSubject.asObservable()

    raiseEvent(eventType: string) {
        this.eventSubject.next(eventType)
    }

    raiseEventOf<T>(eventType: string, data: T) {
        this.eventDataSubject.next({ event: eventType, data: data });
    }
}