import { Injectable, Signal, computed, inject } from '@angular/core'
import { toSignal } from '@angular/core/rxjs-interop'
import { Select, Store } from '@ngxs/store'
import { Observable } from 'rxjs'
import { Device } from '../../../models/Device'
import { Session, Token } from '../../../models/Gateway'
import { EncryptDecryptService } from '../../../services/encrypt-decrypt.service'
import { EnvironmentService } from '../../../services/environment.service'
import { AddDeviceAction, AddSessionAction, AddTokenAction, AppState, ResetGatewayAction } from './app.state'

@Injectable({
    providedIn: 'root'
})
export class AppStateService {

    #encryptDecryptService = inject(EncryptDecryptService)
    #environmentService = inject(EnvironmentService)
    #store: Store = inject(Store)

    @Select(AppState.Device) private deviceSelector$: Observable<string>
    @Select(AppState.Token) private tokenSelector$: Observable<string>
    @Select(AppState.Session) private sessionSelector$: Observable<string>

    #deviceSignal: Signal<string>
    #tokenSignal: Signal<string>
    #sessionSignal: Signal<string>

    constructor() {
        this.#deviceSignal = toSignal(this.deviceSelector$) as Signal<string>
        this.#tokenSignal = toSignal(this.tokenSelector$) as Signal<string>
        this.#sessionSignal = toSignal(this.sessionSelector$) as Signal<string>
    }

    setDevice(device: Device) {
        this.#store.dispatch(new AddDeviceAction(device))
    }

    setToken(token: Token) {
        this.#store.dispatch(new AddTokenAction(token))
    }

    setSession(session: Session) {
        this.#store.dispatch(new AddSessionAction(session))
    }

    resetGatewayAction() {
        this.#store.dispatch(new ResetGatewayAction())
    }

    getDevice = computed(() => {
        return this.decrypt<Device>(this.#deviceSignal())
    })

    getToken = computed(() => {
        return this.decrypt<Token>(this.#tokenSignal())
    })

    getSession = computed(() => {
        return this.decrypt<Session>(this.#sessionSignal() )
    })

    private decrypt<T>(val: string): T {
        return this.#encryptDecryptService.decrypt<T>(val, this.#environmentService.sessionDecryptKey)
    }

}