import { MenuItem } from 'primeng/api'
import { Component, Input, OnInit, ViewChild, inject } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { filter } from 'rxjs'
import { OverlayPanel } from 'primeng/overlaypanel'
import { AppEventService, EVENT_TYPE } from '../../../services/app-event.service'
import { BaseComponent } from '../../../components/base.component'
@Component({
  selector: 'app-mobile-header',
  templateUrl: './mobile-header.component.html',
  styleUrl: './mobile-header.component.scss'
})
export class MobileHeaderComponent extends BaseComponent implements OnInit {
  @Input() items: MenuItem[]
  @ViewChild('mobilemenu') menu: OverlayPanel
  @Input() isLoggedIn: boolean
  #router: Router = inject(Router)
  #eventService: AppEventService = inject(AppEventService)

  ngOnInit(): void {
    this.subscriptions.add(this.#router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        if (this.menu) {
          this.menu.hide()
        }
      }))
  }

  onLogoutClicked(){
    this.#eventService.raiseEvent(EVENT_TYPE.LOGOUT)
  }
}
