import { Component, ElementRef, HostBinding, Input, OnInit, ViewChild, inject } from '@angular/core'
import { MenuItem } from 'primeng/api'
import { Subscription } from 'rxjs'
import { AppMenuService } from '../../../services/app-menu.service'
import { IsActiveMatchOptions } from '@angular/router'
import { AppLayoutService } from '../../../services/app-layout.service'
import { animate, state, style, transition, trigger, AnimationEvent } from '@angular/animations'
import { BaseComponent } from '../../../../../components/base.component'

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrl: './menu-item.component.scss',
  animations: [
    trigger('children', [
      state('collapsed', style({
        height: '0'
      })),
      state('expanded', style({
        height: '*'
      })),
      state('hidden', style({
        display: 'none'
      })),
      state('visible', style({
        display: 'block'
      })),
      transition('collapsed <=> expanded', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ]
})
export class MenuItemComponent extends BaseComponent implements OnInit {

  @Input() item!: MenuItem
  @Input() index!: number
  @Input() @HostBinding('class.layout-root-menuitem') root!: boolean
  @Input() parentKey!: string
  @Input() parentItem: MenuItemComponent
  @Input() isCollapsed: boolean = true;
  @ViewChild('submenu') submenu!: ElementRef

  active = false
  isCurrentRoute = false

  menuSourceSubscription!: Subscription

  menuResetSubscription!: Subscription

  key: string = ""

  menuService: AppMenuService = inject(AppMenuService)
  appLayoutService: AppLayoutService = inject(AppLayoutService)

  ngOnInit(): void {
    this.subscriptions.add(this.menuService.menuSource$.subscribe(value => {
      Promise.resolve(null).then(() => {
        if (value.routeEvent) {
          this.active = (value.key === this.key || value.key.startsWith(this.key + '-')) ? true : false
        }
        else {
          if (value.key === this.item.id || value.key.startsWith(this.item.id + '-')) {
            this.active = true
            if (this.active && this.item.id == value.key && this.item.routerLink && !this.item.items) {
              this.navigateToMenu(this.item)
            }
          } else {
            this.active = false
          }
        }
      })
    }))

    this.subscriptions.add(this.menuService.resetSource$.subscribe(() => {
      this.active = false
    }))
  }

  updateActiveStateFromRoute() {
    if (this.item.routerLink) {
      const activeRoute = this.router.isActive(this.item.routerLink[0], (<IsActiveMatchOptions>this.item.routerLinkActiveOptions || { paths: 'exact', queryParams: 'ignored', matrixParams: 'ignored', fragment: 'ignored' }))
      if (activeRoute) {
        this.menuService.onMenuStateChange({ key: this.key, routeEvent: true })
        this.activateParent()
        return
      }
    }
    this.isCurrentRoute = false
  }

  itemClick(event: MouseEvent | KeyboardEvent) {
    // avoid processing disabled items
    if (this.item.disabled) {
      event.preventDefault()
      return;
    }

    if (this.active) {
      this.active = false;
      return;
    }
    this.active = true
    this.menuService.onMenuStateChange({ key: this.item.id!, routeEvent: false })

    // toggle active state
    if (this.item.items) {
      if (this.root && this.active) {
        this.appLayoutService.onOverlaySubmenuOpen()
      }
    }
  }

  private navigateToMenu(item: MenuItem) {
    this.router.navigate(item.routerLink).finally(() => {
      this.menuService.reset()
    })
  }

  public activateParent() {
    this.isCurrentRoute = true
    if (this.parentItem) {
      this.parentItem.isCurrentRoute = true
      this.parentItem.activateParent()
    }
  }

  calculatePosition(overlay: HTMLElement, target: HTMLElement) {
    if (overlay) {
      const { top } = target.getBoundingClientRect()
      const vHeight = window.innerHeight
      const oHeight = overlay.offsetHeight
      // reset

      let topPosition = 0
      //console.log("oHeight==", oHeight, "===vHeight", vHeight, "===top", top, "===", "===height", height, "===topOffset", topOffset, "===target", target.getBoundingClientRect())
      if ((top + oHeight) >= vHeight) {
        console.log("Menu Overflow reset position")
        topPosition = (vHeight - (top + oHeight))
        overlay.style.top = `${topPosition}px`
      } else {
        console.log("Menu good show at zero position")
        overlay.style.top = '0'
      }
    }
  }

  get submenuAnimation(): string {
    const state = this.active ? 'visible' : 'hidden'
    // if (this.appLayoutService.isDesktop())
    //   state = this.active ? 'visible' : 'hidden'
    // else
    //   state = this.root ? 'expanded' : (this.active ? 'expanded' : 'collapsed')
    console.log("new state for menu", state)
    return state
  }

  onSubmenuAnimated(event: AnimationEvent) {
    if (event.toState === 'visible') {
      const el = <HTMLUListElement>event.element
      const elParent = <HTMLUListElement>el.parentElement
      this.calculatePosition(el, elParent)
    }
  }

}
