import { Injectable, inject } from '@angular/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { LoginFlow } from '../../../models/Login'
import { EncryptDecryptService } from '../../../services/encrypt-decrypt.service'
import { EnvironmentService } from '../../../services/environment.service'

export const LOGIN_STATE_KEY = 'LoginFlowState'
export const LOGIN_ACTION_TYPE = {
  SET_LOGIN_FLOW: `${LOGIN_STATE_KEY}.SET_LOGIN_FLOW`
}


export interface LoginFlowModel {
  loginFlow: unknown
}

export class LoginFlowAction {
  static readonly type = LOGIN_ACTION_TYPE.SET_LOGIN_FLOW
  constructor(public loginFlow: LoginFlow) { }
}


@State<LoginFlowModel>({
  name: LOGIN_STATE_KEY,
  defaults: {
    loginFlow: undefined,
  }
})
@Injectable()
export class LoginFlowState {

  encryptDecryptService: EncryptDecryptService = inject(EncryptDecryptService)
  environmentService: EnvironmentService = inject(EnvironmentService)

  @Action(LoginFlowAction)
  LoginFlowAction({ patchState }: StateContext<LoginFlowModel>, { loginFlow }: LoginFlowAction): void {
    const encryptedloginFlow = this.encryptDecryptService.encrypt(loginFlow, this.environmentService.sessionDecryptKey)
    patchState({ loginFlow: encryptedloginFlow })
  }

  @Selector([LoginFlowState])
  static LoginFlow(state: LoginFlowModel) {
    return state?.loginFlow
  }

}
