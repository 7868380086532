import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-authorized-header',
  templateUrl: './authorized-header.component.html',
  styleUrl: './authorized-header.component.scss'
})
export class AuthorizedHeaderComponent {

  @Input() customClass: string
  @Input() header: string
  @Input() headerCustomClass: string
  @Input() subHeaderCustomClass: string
  @Input() subHeader: string
}
