import { Component, OnInit, inject } from '@angular/core'
import { AppLayoutService } from '../services/app-layout.service'
import { MenuItem } from 'primeng/api'
import { BaseComponent } from '../../../components/base.component'

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './app-breadcrumb.component.html',
  styleUrl: './app-breadcrumb.component.scss'
})
export class AppBreadcrumbComponent extends BaseComponent implements OnInit {

  home: MenuItem
  layoutService: AppLayoutService = inject(AppLayoutService)
  breadCrumbs: MenuItem[] = []

  ngOnInit(): void {
    this.home = { icon: 'pi pi-home', routerLink: '/' }
    this.subscriptions
      .add(
        this.layoutService
          .getBreadCrumbs()
          .subscribe(
            {
              next: (data) => {
                this.breadCrumbs = data
              },
              error: (err) => { throw err }
            }
          )
      )
  }
}
