import { Component, OnInit, inject } from '@angular/core'
import { UserDetails } from '../../../models/Login'
import { BaseComponent } from '../../../components/base.component';
import { AppEventService, EVENT_TYPE } from '../../../services/app-event.service';
import { UserStateService } from '../../../store/state/user/user.state.service';
import { AppStateService } from '../../../store/state/app/app.state.service';
import { Device } from '../../../models/Device';

@Component({
  selector: 'app-profile',
  templateUrl: './app-profile.component.html',
  styleUrl: './app-profile.component.scss'
})
export class AppProfileComponent extends BaseComponent implements OnInit {
  eventService: AppEventService = inject(AppEventService)
  #userStateService = inject(UserStateService)
  #appStateService = inject(AppStateService)
  userDetails: UserDetails;
  deviceInfo : Device;

  constructor() {
    super();
  }
  ngOnInit(): void {
    this.userDetails = this.#userStateService.getUser();
    this.deviceInfo = this.#appStateService.getDevice();
  }

  onLogoutClicked() {
    this.eventService.raiseEvent(EVENT_TYPE.LOGOUT)
  }
}
