import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http'
import { Injectable, inject } from '@angular/core'
import { Observable, map } from 'rxjs'

@Injectable()
export class ExternalDataService {

    #http: HttpClient = inject(HttpClient)

    public get<T>(url: string, headers?: HttpHeaders, responeType: 'json' | 'text' = 'text'): Observable<T> {
        return this.execute<null, T>('get', url, null, headers, responeType)
    }

    public delete<T>(url: string, headers?: HttpHeaders, responeType: 'json' | 'text' = 'text'): Observable<T> {
        return this.execute<null, T>('delete', url, null, headers, responeType)
    }

    public post<B, T>(url: string, body?: B, headers?: HttpHeaders | {
        [header: string]: string | string[]
    }, responeType: 'json' | 'text' = 'text'): Observable<T> {
        return this.execute<B, T>('post', url, body, headers, responeType)      
    }

    public put<B, T>(url: string, body: B, headers?: HttpHeaders, responeType: 'json' | 'text' = 'text'): Observable<T> {
        return this.execute<B, T>('put', url, body, headers, responeType)
    }

    public patch<B, T>(url: string, body: B, headers?: HttpHeaders, responeType: 'json' | 'text' = 'text'): Observable<T> {
        return this.execute<B, T>('patch', url, body, headers, responeType)
    }

    private execute<B, T>(methodType: 'get' | 'post' | 'delete' | 'patch' | 'put', url: string, body?: B, headers?: HttpHeaders | {
        [header: string]: string | string[]
    }, responeType: 'json' | 'text' = 'text'): Observable<T> {
        return this.#http.request(methodType, url, { body: body, headers: headers, responseType: responeType }).pipe(
            map((resp: HttpResponse<unknown>) => {
                if (!resp) {
                    return '' as T
                }
                return JSON.parse(resp as unknown as string) as T
            }))
    }
}