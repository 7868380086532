import * as i0 from '@angular/core';
import { Component, Input, EventEmitter, Output, ViewChild, NgModule } from '@angular/core';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i2 from '@angular/forms';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { BehaviorSubject, interval, take } from 'rxjs';
const _c0 = ["*"];
function SpFormControlErrorComponent_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "small", 1)(2, "i");
    i0.ɵɵtext(3);
    i0.ɵɵelementEnd()();
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("classList", ctx_r0.styleClass);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(ctx_r0.errorMessage);
  }
}
const _c1 = ["formDirective"];
const _c2 = ["spInputValidation", ""];
class SpFormControlErrorComponent {
  constructor() {
    this.styleClass = 'ml-2 p-error';
    this.messages = new Map([['required', {
      message: '{0} is required'
    }], ['minlength', {
      message: 'Password must be at least {0} characters long',
      validatorErrorsKey: ['requiredLength']
    }], ['maxlength', {
      message: 'Password cannot be more than {0} characters long',
      validatorErrorsKey: ['requiredLength']
    }], ['email', {
      message: 'Please enter a valid email address'
    }], ['pattern', {
      message: 'Please enter a valid {0}'
    }]]);
  }
  get errorMessage() {
    for (const validatorName in this.control?.errors) {
      if (this.control.touched) return this.getValidatorErrorMessage(validatorName, this.control.errors[validatorName]);
    }
    return null;
  }
  getValidatorErrorMessage(validatorName, validatorErrors) {
    let result = null;
    if (this.messagesParameters && this.messagesParameters.find(x => x.key === validatorName)) {
      result = this.messagesParameters.find(x => x.key === validatorName)?.message;
    } else {
      let args = this.messages.get(validatorName)?.validatorErrorsKey?.map(name => validatorErrors?.[name]);
      if (this.parameters && this.parameters.length) {
        args = args || [];
        args?.push(...this.parameters);
      }
      result = args ? this.stringFormat(this.messages.get(validatorName)?.message, ...args) : this.messages.get(validatorName)?.message;
    }
    return result;
  }
  stringFormat(template, ...args) {
    if (template) {
      return template.replace(/{(\d+)}/g, (match, index) => {
        return typeof args[index] !== 'undefined' ? args[index] : match;
      });
    }
    return undefined;
  }
  static {
    this.ɵfac = function SpFormControlErrorComponent_Factory(t) {
      return new (t || SpFormControlErrorComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SpFormControlErrorComponent,
      selectors: [["sp-control-error"]],
      inputs: {
        control: "control",
        parameters: "parameters",
        messagesParameters: "messagesParameters",
        styleClass: "styleClass"
      },
      ngContentSelectors: _c0,
      decls: 2,
      vars: 1,
      consts: [[4, "ngIf"], [3, "classList"]],
      template: function SpFormControlErrorComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
          i0.ɵɵtemplate(1, SpFormControlErrorComponent_ng_container_1_Template, 4, 2, "ng-container", 0);
        }
        if (rf & 2) {
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.errorMessage !== null);
        }
      },
      dependencies: [i1.NgIf],
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SpFormControlErrorComponent, [{
    type: Component,
    args: [{
      selector: 'sp-control-error',
      template: `
  <ng-content></ng-content>
  <ng-container *ngIf="errorMessage !== null">
  <small [classList]="styleClass">
  <i>{{errorMessage}}</i>
  </small>
  </ng-container>`
    }]
  }], null, {
    control: [{
      type: Input
    }],
    parameters: [{
      type: Input
    }],
    messagesParameters: [{
      type: Input
    }],
    styleClass: [{
      type: Input
    }]
  });
})();
class SaraPlusFormWrapperComponent {
  async onSubmit() {
    this.submitted = true;
    this.formGroup.markAllAsTouched();
    this.submittedClass = 'sp-submitted';
    await this.OnSubmitted.emit(true);
  }
  async Reset() {
    this.submitted = false;
    this.formGroup.markAsUntouched();
    this.formGroup.reset();
    this.formDirective?.resetForm();
    this.submittedClass = '';
    await this.OnSubmitted.emit(false);
  }
  constructor(elem) {
    this.elem = elem;
    this.formGroup = new FormGroup({});
    this.submitted$ = new BehaviorSubject(false);
    this.OnSubmitted = new EventEmitter(true);
    this.submitted = false;
    this.submittedClass = '';
    this.animationClassName = 'sp-invalid-animation';
    this.controls = [];
  }
  ngOnDestroy() {
    this.removeListeners();
  }
  ngAfterViewInit() {
    //Using interval because sometime control take longer to render
    interval(1000).pipe(take(1)).subscribe(x => {
      this.controls = this.getValidationControl();
      if (this.controls.length > 0) this.addlisteners();
    });
  }
  getValidationControl() {
    let elems = this.elem.nativeElement.querySelectorAll('.p-element');
    let counter = 0;
    const result = [];
    let lastParent = undefined;
    while (true) {
      let element = elems[counter];
      if (element) {
        if (this.isInputControl(element)) {
          result.push({
            control: element,
            parent: lastParent || element
          });
          lastParent = undefined;
        } else if (this.isInputWrapper(element)) {
          lastParent = element;
        }
      } else {
        break;
      }
      counter++;
    }
    return result;
  }
  isInputControl(element) {
    return element.nodeName.toLowerCase() == 'input';
  }
  isInputWrapper(element) {
    return element.matches('.p-element') && element.nodeType.toString().toLowerCase() != 'input' && element.matches('.p-inputwrapper');
  }
  onBlur(e) {
    if (e.target) {
      let elem = e.target;
      let parent = this.controls.find(x => x.control == e.target)?.parent;
      console.log(parent);
      if (parent?.matches('.ng-touched.ng-invalid')) {
        elem?.classList.add(this.animationClassName);
      }
    }
  }
  onFocus(e) {
    if (e.target) {
      let elem = e.target;
      if (elem.matches(`.${this.animationClassName}`)) {
        elem.classList.remove(this.animationClassName);
      }
    }
  }
  addlisteners() {
    if (this.controls) {
      this.controls.forEach(inputControl => {
        inputControl.control.addEventListener('focus', this.onFocus.bind(this));
        inputControl.control.addEventListener('blur', this.onBlur.bind(this));
      });
    }
  }
  removeListeners() {
    if (this.controls) {
      this.controls.forEach(inputControl => {
        inputControl.control.removeEventListener('focus', this.onFocus.bind(this));
        inputControl.control.removeEventListener('blur', this.onBlur.bind(this));
      });
    }
  }
  static {
    this.ɵfac = function SaraPlusFormWrapperComponent_Factory(t) {
      return new (t || SaraPlusFormWrapperComponent)(i0.ɵɵdirectiveInject(i0.ElementRef));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SaraPlusFormWrapperComponent,
      selectors: [["sp-form-wrapper"]],
      viewQuery: function SaraPlusFormWrapperComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(_c1, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.formDirective = _t.first);
        }
      },
      inputs: {
        formGroup: "formGroup"
      },
      outputs: {
        OnSubmitted: "OnSubmitted"
      },
      ngContentSelectors: _c0,
      decls: 3,
      vars: 2,
      consts: [["formDirective", "ngForm"], ["novalidate", "", 3, "submit", "formGroup", "ngClass"]],
      template: function SaraPlusFormWrapperComponent_Template(rf, ctx) {
        if (rf & 1) {
          const _r1 = i0.ɵɵgetCurrentView();
          i0.ɵɵprojectionDef();
          i0.ɵɵelementStart(0, "form", 1, 0);
          i0.ɵɵlistener("submit", function SaraPlusFormWrapperComponent_Template_form_submit_0_listener() {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.onSubmit());
          });
          i0.ɵɵprojection(2);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵproperty("formGroup", ctx.formGroup)("ngClass", ctx.submittedClass);
        }
      },
      dependencies: [i1.NgClass, i2.ɵNgNoValidate, i2.NgControlStatusGroup, i2.FormGroupDirective],
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SaraPlusFormWrapperComponent, [{
    type: Component,
    args: [{
      selector: 'sp-form-wrapper',
      template: `
  <form #formDirective="ngForm" (submit)="onSubmit()" [formGroup]="formGroup" novalidate [ngClass]="submittedClass">
  <ng-content></ng-content>
  </form>`
    }]
  }], () => [{
    type: i0.ElementRef
  }], {
    formGroup: [{
      type: Input,
      args: ['formGroup']
    }],
    OnSubmitted: [{
      type: Output
    }],
    formDirective: [{
      type: ViewChild,
      args: ['formDirective']
    }]
  });
})();
class SaraPlusFormModule {
  static {
    this.ɵfac = function SaraPlusFormModule_Factory(t) {
      return new (t || SaraPlusFormModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: SaraPlusFormModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule, ReactiveFormsModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SaraPlusFormModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, ReactiveFormsModule],
      exports: [SaraPlusFormWrapperComponent, SpFormControlErrorComponent],
      declarations: [SaraPlusFormWrapperComponent, SpFormControlErrorComponent]
    }]
  }], null, null);
})();
class InputValidationComponent {
  constructor(elem) {
    this.elem = elem;
  }
  ngAfterViewInit() {
    //Using interval because sometime control take longer to render
    interval(1000).pipe(take(1)).subscribe(x => {
      this.inputControl = this.inputElement();
      if (!this.inputControl) throw 'Invalid use of component';
      this.addlisteners();
    });
  }
  ngOnInit() {}
  inputElement() {
    let htmlElement = this.elem.nativeElement;
    if (htmlElement.classList.contains('p-inputtext')) {
      return htmlElement;
    }
    let elems = this.elem.nativeElement.querySelectorAll('.p-component.p-element.p-inputtext');
    if (elems && elems.length > 0) return elems[0];else return undefined;
  }
  onBlur() {
    if (this.elem.nativeElement?.matches('.ng-touched.ng-invalid')) {
      this.elem.nativeElement.classList.add('sp-invalid');
    }
  }
  onFocus() {
    if (this.elem.nativeElement?.matches('.sp-invalid')) {
      this.elem.nativeElement.classList.remove('sp-invalid');
    }
  }
  addlisteners() {
    if (this.inputControl) {
      this.inputControl.addEventListener('focus', this.onFocus.bind(this));
      this.inputControl.addEventListener('blur', this.onBlur.bind(this));
    }
  }
  removeListeners() {
    if (this.inputControl) {
      this.inputControl.removeEventListener('focus', this.onFocus.bind(this));
      this.inputControl.removeEventListener('blur', this.onBlur.bind(this));
    }
  }
  ngOnDestroy() {
    this.removeListeners();
  }
  static {
    this.ɵfac = function InputValidationComponent_Factory(t) {
      return new (t || InputValidationComponent)(i0.ɵɵdirectiveInject(i0.ElementRef));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: InputValidationComponent,
      selectors: [["", "spInputValidation", ""]],
      attrs: _c2,
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function InputValidationComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(InputValidationComponent, [{
    type: Component,
    args: [{
      selector: '[spInputValidation]',
      template: `
    <ng-content></ng-content>
  `
    }]
  }], () => [{
    type: i0.ElementRef
  }], null);
})();
class InputValidationComponentModule {
  static {
    this.ɵfac = function InputValidationComponentModule_Factory(t) {
      return new (t || InputValidationComponentModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: InputValidationComponentModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(InputValidationComponentModule, [{
    type: NgModule,
    args: [{
      declarations: [InputValidationComponent],
      imports: [CommonModule],
      exports: [InputValidationComponent]
    }]
  }], null, null);
})();

/*
 * Public API Surface of saraplus-ngx-forms
 */

/**
 * Generated bundle index. Do not edit.
 */

export { InputValidationComponent, InputValidationComponentModule, SaraPlusFormModule, SaraPlusFormWrapperComponent, SpFormControlErrorComponent };
