import { Injectable, inject } from '@angular/core'
import { UserStateService } from '../store/state/user/user.state.service'

@Injectable({
  providedIn: 'root'
})
export class RolesService {

  #userStateService = inject(UserStateService)

  getRoles() {
    return ['admin', 'user']
  }

  getUserModules(): string[] | undefined {
    return this.#userStateService.getUserWelcomeInfo()?.Modules;
  }
}
