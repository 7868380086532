<div class="flex justify-content-center align-content-center align-items-center text-white font-bold"
    *ngIf="userDetails">
    <div class="flex gap-2 align-content-center justify-content-center align-items-center" *ngIf="userDetails">
        {{userDetails.LoginEmail}}
    </div>
    <p-divider [align]="'left'" type="solid" layout="vertical" styleClass="py-1 border-1"></p-divider>
    <div class="flex">
        <div>
            <p-button (onClick)="op.toggle($event)" icon="pi pi-user-circle" styleClass="text-white p-button-lg"
                [rounded]="true" [text]="true">
                <p-overlayPanel #op>
                    <div class="flex flex-column max-w-25rem">
                        <div>
                            <ul class="list-none p-0 m-0 flex flex-column gap-3">
                                <li class="flex align-items-center gap-2">
                                    <span>IP Address : {{ userDetails.DeviceIP }}</span>
                                </li>
                                <li class="flex align-items-center gap-2">
                                    <span>Name : <span>{{userDetails.FirstName}}</span><span
                                            *ngIf="userDetails.LastName">&nbsp;{{userDetails.LastName}}</span></span>
                                </li>
                                <li class="flex align-items-center gap-2">
                                    <span>Email : {{ userDetails.LoginEmail }}</span>
                                </li>
                                <li class="flex align-items-center gap-2" *ngIf="deviceInfo">
                                    <span>Lat: {{ deviceInfo.latitude }}, Long: {{deviceInfo.longitude}}</span>
                                </li>
                                <li class="flex align-items-center gap-2" *ngIf="deviceInfo">
                                    <span>Device Info : {{ deviceInfo.deviceType }}, {{deviceInfo.platform}},
                                        {{deviceInfo.browserName}}</span>
                                </li>
                                <li class="flex align-items-center gap-2">
                                    <p-button class="w-full" icon="pi pi-bell" label="Notifications" badge="0"
                                        styleClass="min-w-full"></p-button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </p-overlayPanel>
            </p-button>
        </div>
        <div>
            <p-button icon="pi pi-logout" (onClick)="onLogoutClicked()" styleClass="text-white p-button-lg"
                [rounded]="true" [text]="true" />
        </div>
    </div>
</div>