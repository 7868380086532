import { Injectable } from '@angular/core'
import { MenuItem } from 'primeng/api'
import { BehaviorSubject, Observable, Subject } from 'rxjs'

@Injectable()
export class AppLayoutService {

  private breadCrumbSubject: BehaviorSubject<MenuItem[]> = new BehaviorSubject<MenuItem[]>([])
  private breadCrumbs: MenuItem[] = []
  private breadCrumbObservable: Observable<MenuItem[]> = this.breadCrumbSubject.asObservable()
  private overlayOpen = new Subject<unknown>()
  overlayOpen$ = this.overlayOpen.asObservable()


  public addBreadCrumbs(items: MenuItem[]) {
    this.breadCrumbs = items
    this.breadCrumbSubject.next(this.breadCrumbs)
  }

  public resetBreadCrumbs() {
    this.breadCrumbs = []
    this.breadCrumbSubject.next(this.breadCrumbs)
  }

  public getBreadCrumbs(): Observable<MenuItem[]> {
    return this.breadCrumbObservable
  }

  public initBreadCrumb(breadCrumbs: MenuItem[]) {
    this.breadCrumbs = breadCrumbs
    this.breadCrumbSubject.next(this.breadCrumbs)
  }

  isDesktop() {
    return window.innerWidth > 991
  }
  onOverlaySubmenuOpen() {
    this.overlayOpen.next(null)
  }
}
