import { Injectable, inject } from '@angular/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { PreOrderDetails, StartOrderDetails } from '../../../models/OrderDetails'
import { EncryptDecryptService } from '../../../services/encrypt-decrypt.service'
import { EnvironmentService } from '../../../services/environment.service'
import { AvailableServices } from '../../../models/AvailableServices'

export const ORDER_STATE_KEY = 'OrderState'
export const ORDER_ACTION_TYPE = {
  SET_PRE_ORDER: `${ORDER_STATE_KEY}.SET_PRE_ORDER`,
  SET_START_ORDER: `${ORDER_STATE_KEY}.SET_START_ORDER`,
  SET_AVAILABLE_SERVICES: `${ORDER_STATE_KEY}.SET_AVAILABLE_SERVICES`
}

export interface OrderStateModel {
  preOrder?: unknown
  startOrder?: unknown
  availableServices?: unknown
}

export class AddPreOrderAction {
  static readonly type = ORDER_ACTION_TYPE.SET_PRE_ORDER
  constructor(public preOrderDetails: PreOrderDetails) { }
}

export class AddStartOrderAction {
    static readonly type = ORDER_ACTION_TYPE.SET_START_ORDER
    constructor(public startOrderDetails: StartOrderDetails) { }
}
  
export class AddAvailableServicesAction {
  static readonly type = ORDER_ACTION_TYPE.SET_AVAILABLE_SERVICES
  constructor(public availableServices: AvailableServices) { }
}

@State<OrderStateModel>({
  name: ORDER_STATE_KEY,
  defaults: {
    preOrder: undefined,
    startOrder: undefined,
    availableServices: undefined
  }
})
@Injectable()
export class OrderState {

  encryptDecryptService: EncryptDecryptService = inject(EncryptDecryptService)
  environmentService: EnvironmentService = inject(EnvironmentService)

  @Action(AddPreOrderAction)
  addPreOrder({ patchState }: StateContext<OrderStateModel>, { preOrderDetails }: AddPreOrderAction): void {
    const encryptedUserInfo = this.encryptDecryptService.encrypt(preOrderDetails, this.environmentService.sessionDecryptKey)
    patchState({ preOrder: encryptedUserInfo })
  }

  @Action(AddStartOrderAction)
  addStartOrder({ patchState }: StateContext<OrderStateModel>, { startOrderDetails }: AddStartOrderAction): void {
    const encryptedUserInfo = this.encryptDecryptService.encrypt(startOrderDetails, this.environmentService.sessionDecryptKey)
    patchState({ startOrder: encryptedUserInfo })
  }

  @Action(AddAvailableServicesAction)
  addAvailableServices({ patchState }: StateContext<OrderStateModel>, { availableServices }: AddAvailableServicesAction): void {
    const encryptedUserInfo = this.encryptDecryptService.encrypt(availableServices, this.environmentService.sessionDecryptKey)
    patchState({ availableServices: encryptedUserInfo })
  }


  @Selector([OrderState])
  static orderInfo(state: OrderStateModel) {
    return state
  }

  @Selector([OrderState])
  static preOrderInfo(state: OrderStateModel) {
    return state?.preOrder
  }

  @Selector([OrderState])
  static startOrderInfo(state: OrderStateModel) {
    return state?.startOrder
  }

  @Selector([OrderState])
  static availableServices(state: OrderStateModel) {
    return state?.availableServices
  }

}
