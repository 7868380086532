<div [class]="customClass">
   <div class="w-full lg:w-10 xl:w-8 flex flex-column">
      <h1 [class]="headerCustomClass">
         {{header}}
      </h1>
      <span [class]="subHeaderCustomClass">
         {{subHeader}}
      </span>
   </div>
</div>
<hr>