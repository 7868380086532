<div class="sidebar md:flex flex-column surface-900 z-5 hidden relative" #rootmenu>
    <div class="flex h-4rem justify-content-center">
        <a class="flex align-items-center cursor-pointer logo" routerLink="/welcome">
            <img src="assets/images/logo/saraplus_logo_menu.png" alt="Sara plus" />
        </a>
    </div>
    <div class="flex-grow-1">
        <ul class="list-none px-0 m-0 hidden md:block">
            <li class="mb-1" *ngFor="let item of items; let i = index;">
                <app-menu-item [item]="item" [index]="i" [root]="true" [isCollapsed]="isCollapsed"></app-menu-item>
            </li>
        </ul>
    </div>
    <div class="absolute left-0 min-h-screen min-w-full top-0 cursor-pointer" (click)="$event.preventDefault()" [hidden]="!isCollapsed || deviceType !== 'Web'">
<!-- This is place holder for menu to be clicked on ipad devices -->
    </div>
</div>