import { Component, inject, OnDestroy } from "@angular/core"
import { filter, Subscription } from "rxjs"
import { TitleService } from "../services/title.service";
import { NavigationEnd, Router } from "@angular/router";

@Component({
    template: ''
})
export abstract class BaseComponent implements OnDestroy {

    protected subscriptions = new Subscription()
    protected titleService: TitleService = inject(TitleService);
    protected router: Router = inject(Router);

    /**
     *
     */
    constructor() {
        this.router.events.pipe(filter(event => event instanceof NavigationEnd))
            .subscribe(() => {
                this.setTitle(undefined);
            })
    }
    ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }

    setTitle(title: string, subTitle?: string) {
        this.titleService.update({ title: title, subTitle: subTitle });
    }
}