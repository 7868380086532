import { Action, Selector, State, StateContext } from '@ngxs/store'
import { Injectable, inject } from '@angular/core'
import { Token, Session } from '../../../models/Gateway'
import { EncryptDecryptService } from '../../../services/encrypt-decrypt.service'
import { EnvironmentService } from '../../../services/environment.service'
import { Device } from '../../../models/Device'

export const APP_STATE_KEY = 'AppState'
export const APP_ACTION_TYPE = {
    SET_DEVICE: `${APP_STATE_KEY}.SET_DEVICE`,
    SET_GATEWAY_TOKEN: `${APP_STATE_KEY}.SET_GATEWAY_TOKEN`,
    SET_GATEWAY_SESSION: `${APP_STATE_KEY}.SET_GATEWAY_SESSION`,
    RESET_GATEWAY: `${APP_STATE_KEY}.RESET_GATEWAY`,
}
export interface AppStateModel {
    device?: unknown
    gateway?: {
        token?: unknown
        session?: unknown
    }
}

export class AddDeviceAction {
    static readonly type = APP_ACTION_TYPE.SET_DEVICE
    constructor(public device: Device) { }
}
export class AddTokenAction {
    static readonly type = APP_ACTION_TYPE.SET_GATEWAY_TOKEN
    constructor(public token: Token) { }
}
export class AddSessionAction {
    static readonly type = APP_ACTION_TYPE.SET_GATEWAY_SESSION
    constructor(public session: Session) { }
}

export class ResetGatewayAction {
    static readonly type = APP_ACTION_TYPE.RESET_GATEWAY
    constructor() { }
}

@State<AppStateModel>({
    name: APP_STATE_KEY,
    defaults: {}
})
@Injectable()
export class AppState {

     encryptDecryptService: EncryptDecryptService = inject(EncryptDecryptService)
     environmentService: EnvironmentService = inject(EnvironmentService)


    @Action(AddDeviceAction)
    addDevice({ patchState }: StateContext<AppStateModel>, { device }: AddDeviceAction): void {
        const encryptedDevice = this.encryptDecryptService.encrypt(device, this.environmentService.sessionDecryptKey)
        patchState({ device: encryptedDevice })
    }

    @Action(AddTokenAction)
    addToken({ patchState, getState }: StateContext<AppStateModel>, { token }: AddTokenAction): void {
        if (!token) {
            return
        }
        const encryptedInfo = this.encryptDecryptService.encrypt(token, this.environmentService.sessionDecryptKey)
        const { gateway } = getState()
        let gatewayToPatch = {...gateway}

        if (!gateway) {
            gatewayToPatch = { token: encryptedInfo }
        } else if (token) {
            gatewayToPatch.token = encryptedInfo
        }
        patchState({ gateway: gatewayToPatch })
    }

    @Action(AddSessionAction)
    addSession({ patchState, getState }: StateContext<AppStateModel>, { session }: AddSessionAction): void {
        if (!session) {
            return
        }
        const encryptedInfo = this.encryptDecryptService.encrypt(session, this.environmentService.sessionDecryptKey)
        const { gateway } = getState()
        let gatewayToPatch = {...gateway}
        if (!gateway) {
            gatewayToPatch = { session: encryptedInfo }
        } else if (session) {
            gatewayToPatch.session = encryptedInfo
        }
        patchState({ gateway: gatewayToPatch })
    }

    @Action(ResetGatewayAction)
    resetGatewayAction(ctx: StateContext<AppStateModel>): void {
        const state = ctx.getState()
        ctx.setState({
            device: state.device
        })
    }

    @Selector([AppState])
    static Device(state: AppStateModel) {
        return state.device
    }
    @Selector([AppState])
    static Token(state: AppStateModel) {
        return state.gateway?.token
    }
    @Selector([AppState])
    static Session(state: AppStateModel) {
        return state.gateway?.session
    }
}