import { Injectable } from '@angular/core'
import { environment } from '../../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {

  getUrl(url: string): string {
    return environment.apiUrl + url
  }

  getIgnoreEncryption(): boolean {
    return environment.ignoreEncryption
  }


  get sessionDecryptKey() {
    return environment.sessionDecryptKey
  }

  get env() {
    return {...environment}
  }
}
