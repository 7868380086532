import { Component, ElementRef, HostListener, Input, OnInit, QueryList, Renderer2, ViewChild, ViewChildren, inject } from '@angular/core'
import { MenuItem } from 'primeng/api'
import { TieredMenu } from 'primeng/tieredmenu'
import { Subscription } from 'rxjs'
import { BaseComponent } from '../../../components/base.component'
import { AppLayoutService } from '../services/app-layout.service'
import { AppMenuService } from '../services/app-menu.service'
import { AppEventService, EVENT_TYPE } from '../../../services/app-event.service'
import { DeviceService } from '../../../services/device.service'

@Component({
  selector: 'app-sidebar',
  templateUrl: './app-sidebar.component.html',
  styleUrl: './app-sidebar.component.scss'
})
export class AppSidebarComponent extends BaseComponent implements OnInit {


  layoutService: AppLayoutService = inject(AppLayoutService)
  deviceService: DeviceService = inject(DeviceService)
  menuService: AppMenuService = inject(AppMenuService)
  renderer: Renderer2 = inject(Renderer2)
  eventService: AppEventService = inject(AppEventService)
  @Input() items: MenuItem[]
  @ViewChildren(TieredMenu) childMenus!: QueryList<TieredMenu>
  private breadCrumbs: MenuItem[] = []
  private selectedMenu: MenuItem
  overlayMenuOpenSubscription: Subscription
  menuOutsideClickListener: unknown
  @ViewChild('rootmenu') rootmenu!: ElementRef<HTMLElement>
  @Input() isLoggedIn: boolean;
  isCollapsed = true;
  signOutMenuItem: MenuItem = { id: 'SignOutButton', label: 'Sign Out', icon: 'pi pi-power-off' };
  collapseMenuItem: MenuItem = { id: 'collapseMenuItem', label: '', icon: 'pi pi-align-left' };
  deviceType = this.deviceService.getDeviceType();


  ngOnInit(): void {
    this.menuService.reset();
    this.isCollapsed = true;
    this.raiseCollapseEvent();
  }

  setBreadCrumbs() {
    this.layoutService.addBreadCrumbs(this.breadCrumbs)
  }

  onMenuClicked($event: MouseEvent, item: MenuItem) {
    if (this.selectedMenu != item) {
      this.breadCrumbs = []
    } else if (this.selectedMenu == item) {
      this.breadCrumbs.pop()
    }
    this.breadCrumbs.push(item)
    if (item.items && item.items.length > 0) {
      this.selectedMenu = item
      const subMenu = this.childMenus.find(x => x.id == 'menu-' + item.id)
      if (subMenu) {
        subMenu.toggle($event)
      }
    } else {
      if (this.selectedMenu != item) {
        this.layoutService.resetBreadCrumbs()
      }
      this.navigateToMenu(item)
    }
  }

  onSubMenuItemClicked($event: MouseEvent, item: MenuItem) {
    this.breadCrumbs.push(item)
    if (!item.items || item.items.length == 0) {
      this.navigateToMenu(item)
    }
  }

  private findMenuItemsByUrl(url: string, item: MenuItem): MenuItem[] {
    const result: MenuItem[] = []
    if (item.items && item.items.length > 0) {
      for (let index = 0; index < item.items.length; index++) {
        const subMenus = this.findMenuItemsByUrl(url, item.items[index])
        subMenus.forEach(element => {
          result.push(element)
        });

        if (subMenus.length > 0) {
          result.push(item.items[index])
          break
        }
      }
    } else {
      if (item.routerLink && url.indexOf(item.routerLink[0]) > -1) {
        result.push(item)
      }
    }
    return result
  }

  private navigateToMenu(item: MenuItem) {
    this.router.navigate(item.routerLink)
      .then(() => {
        this.layoutService.resetBreadCrumbs()
        this.setBreadCrumbs();
      })
      .catch(() => {
        this.layoutService.resetBreadCrumbs()
        this.breadCrumbs = []
      })
  }

  onLogoutClicked() {
    this.eventService.raiseEvent(EVENT_TYPE.LOGOUT)
  }

  onCollapsedClicked() {
    this.isCollapsed = !this.isCollapsed;
    this.raiseCollapseEvent();
  }

  @HostListener('mouseenter', ['$event'])
  handleMouseEnterEvent() {
    if (this.deviceType == 'Web') {
      this.isCollapsed = false;
      this.raiseCollapseEvent();
    }
  }

  @HostListener('mouseleave', ['$event'])
  handleMouseLeaveEvent() {
    if (this.deviceType == 'Web') {
      this.isCollapsed = true;
      this.raiseCollapseEvent();
    }
  }

  @HostListener('click', ['$event'])
  handleDoubleClickEvent(event: MouseEvent) {
    this.isCollapsed = false;
    this.raiseCollapseEvent();
    event.preventDefault();
  }

  @HostListener('document:click', ['$event'])
  onKeyUp(event: MouseEvent) {
    const isOutsideClicked = !this.rootmenu?.nativeElement.contains(event.target as any);
    if (isOutsideClicked) {
      this.menuService.reset();
      this.isCollapsed = true;
      this.raiseCollapseEvent();
    }
  }


  raiseCollapseEvent() {
    this.eventService.raiseEventOf<boolean>(EVENT_TYPE.MENU_COLLAPSED, this.isCollapsed);
    if (this.isCollapsed) {
      this.menuService.reset();
    }
  }

  onSidebarOpenClicked() {
    this.isCollapsed = false;
    this.raiseCollapseEvent();
  }
}
