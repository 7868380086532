import { Injectable, inject } from '@angular/core'
import { MessageService } from 'primeng/api'

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  toast: MessageService = inject(MessageService)

  showSuccess(message: string, title?: string, key: string = 'maintoast') {
    this.toast.clear(key)
    this.toast.add({ severity: 'success', summary: title, detail: message, key: key })
  }

  showError(message: string, title?: string, key: string = 'maintoast') {
    this.toast.clear(key)
    this.toast.add({ severity: 'error', summary: title, detail: message, key: key, life: 3000 })
  }

  showInfo(message: string, title?: string, key: string = 'maintoast') {
    this.toast.clear(key)
    this.toast.add({ severity: 'info', summary: title, detail: message, key: key })
  }

  showWarning(message: string, title?: string, key: string = 'maintoast') {
    this.toast.clear(key)
    this.toast.add({ severity: 'warn', summary: title, detail: message, key: key })
  }
}
