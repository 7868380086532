import { HttpClient, HttpHeaders, HttpResponse, HttpStatusCode } from '@angular/common/http'
import { Injectable, inject } from '@angular/core'
import { Observable, map } from 'rxjs'
import { EncryptDecryptService } from './encrypt-decrypt.service'
import { EnvironmentService } from './environment.service'
import { AppStateService } from '../store/state/app/app.state.service'

@Injectable({
    providedIn: 'root'
})
export class DataService {

    #http: HttpClient = inject(HttpClient)
    #encryptDecryptService: EncryptDecryptService = inject(EncryptDecryptService)
    #environmentService: EnvironmentService = inject(EnvironmentService)
    #appStateService = inject(AppStateService)

    public get<T>(url: string, headers?: HttpHeaders, skipErrorIntercept: boolean = false, responeType: 'json' | 'text' = 'text'): Observable<T> {
        return this.execute<null, T>('get', url, null, headers, skipErrorIntercept, responeType)
    }

    public delete<T>(url: string, headers?: HttpHeaders, skipErrorIntercept: boolean = false, responeType: 'json' | 'text' = 'text'): Observable<T> {
        return this.execute<null, T>('delete', url, null, headers, skipErrorIntercept, responeType)
    }

    public post<B, T>(url: string, body?: B, headers?: HttpHeaders | {
        [header: string]: string | string[]
    }, skipErrorIntercept: boolean = false, responeType: 'json' | 'text' = 'text'): Observable<T> {
        return this.execute<B, T>('post', url, body, headers, skipErrorIntercept, responeType)
    }

    public put<B, T>(url: string, body: B, headers?: HttpHeaders, skipErrorIntercept: boolean = false, responeType: 'json' | 'text' = 'text'): Observable<T> {
        return this.execute<B, T>('put', url, body, headers, skipErrorIntercept, responeType)
    }

    public patch<B, T>(url: string, body: B, headers?: HttpHeaders, skipErrorIntercept: boolean = false, responeType: 'json' | 'text' = 'text'): Observable<T> {
        return this.execute<B, T>('patch', url, body, headers, skipErrorIntercept, responeType)
    }

    private execute<B, T>(methodType: 'get' | 'post' | 'delete' | 'patch' | 'put', url: string, body?: B, headers?: HttpHeaders | {
        [header: string]: string | string[]
    }, skipErrorIntercept: boolean = false, responeType: 'json' | 'text' = 'text'): Observable<T> {

        if (skipErrorIntercept) {
            if (!headers) {
                headers = new HttpHeaders();
            }
            headers = (headers as HttpHeaders).append('skip', 'true');
        }

        return this.#http.request(methodType, url, { body: body, headers: headers, responseType: responeType }).pipe(
            map((resp: HttpResponse<unknown>) => {
                if (!resp) {
                    return '' as T
                }
                const decryptionKey = this.getKeyToDecrypt(url, methodType)
                if (!decryptionKey || resp.status === HttpStatusCode.NoContent) return resp as T
                return this.decryptResponse<T>(resp, decryptionKey)

            }))
    }

    private getKeyToDecrypt(url: string, methodType: string) {
        if (url.includes('oauth/token')) {
            return null
        }
        if (url.includes('ng/v1/common/startSession')) {
            return this.#environmentService.sessionDecryptKey
        } else if (methodType === 'post') {
            return this.#appStateService.getSession()?.ClientAesKey
        } else {
            return this.#appStateService.getSession()?.ClientAesKey
        }
        return null
    }


    private decryptResponse<T>(resp: unknown, key: string): T {
        return this.#encryptDecryptService.decrypt<T>(resp as string, key)
    }
}