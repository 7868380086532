<ng-container *ngIf="item.visible !== false">
    <a pRipple tabindex="0" class="text-sm flex py-3
        align-items-center cursor-pointer menu-item
        transition-duration-150 transition-colors justify-content-center"
        [ngClass]="[root ? 'justify-content-center flex-column border-round root-menu-item':'justify-content-between', isCurrentRoute && root ? 'active-menu border-bottom-1 border-top-1' : '', this.active ? 'selected' : '', isCollapsed ? 'isCollapsed' : 'isExpanded']"
        (click)="itemClick($event)" (keyup)="itemClick($event)">
        <ng-container *ngIf="root" [ngTemplateOutlet]="rootMenuTemplate">
        </ng-container>
        <ng-container *ngIf="!root" [ngTemplateOutlet]="submenuTemplate">
        </ng-container>
    </a>
    <ul #submenu *ngIf="item.items && active === true" class="z-1 list-none bg-gray-800 p-0 min-w-max"
        [@children]="submenuAnimation" (@children.done)="onSubmenuAnimated($event)">
        <ng-template ngFor let-child let-i="index" [ngForOf]="item.items">
            <li>
                <app-menu-item [item]="child" [index]="i" [parentKey]="key" [parentItem]="this"
                    class="submenu-item"></app-menu-item>
            </li>
        </ng-template>
    </ul>
</ng-container>

<ng-template #rootMenuTemplate>
    <div class="flex w-full px-3">
        <div class="flex flex-1 align-items-center gap-3">
            <i class="pi font-bold text-4xl" *ngIf="item.icon" [ngClass]="item.icon"></i>
            <span class="text-lg" *ngIf="!isCollapsed">{{item.label}}</span>
        </div>
        <div class="flex align-items-center" *ngIf="!isCollapsed">
            <i class="pi pi-angle-down" [ngClass]="{'pi-angle-up': active}" *ngIf="item.items"></i>
        </div>
    </div>
</ng-template>

<ng-template #submenuTemplate>
    <div class="flex w-full px-3">
        <div class="flex flex-1 align-items-center gap-3">
            <i class="pi font-bold text-4xl" *ngIf="item.icon" [ngClass]="item.icon"></i>
            <span>{{item.label}}</span>
        </div>
        <div class="flex align-items-center">
            <i class="pi pi-angle-down" [ngClass]="{'pi-angle-up': active}" *ngIf="item.items && !root"></i>
        </div>
    </div>
</ng-template>