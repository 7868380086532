import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http'
import { Injectable, inject } from '@angular/core'
import { Observable, delay, finalize, map, of, switchMap, tap } from 'rxjs'
import { AppStateService } from '../store/state/app/app.state.service'
import { UserStateService } from '../store/state/user/user.state.service'
import { DataService } from './data.service'
import { DeviceService } from './device.service'
import { EnvironmentService } from './environment.service'
import { LoginReq, LoginResp, UserWelcomeResponse } from '../models/Login'
import { CreatePasswordReq } from '../models/CreatePassword'
import { ForgotPasswordResp, ForgotPasswordReq } from '../models/ForgotPassword'
import { SessionTypeEnum } from '../models/SessionTypeEnum'
import { ValidatePasscodeResp, ValidatePasscodeReq } from '../models/ValidatePasscode'
import * as uuid from 'uuid'
import { Device } from '../models/Device';
import { Session, Token } from '../models/Gateway';
import { AppEventService, EVENT_TYPE } from './app-event.service';
import { LoginFlowStateService } from '../store/state/login-flow/login-flow.state.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  #environmentService = inject(EnvironmentService)
  #dataService = inject(DataService)
  #deviceService = inject(DeviceService)
  #appStateService = inject(AppStateService)
  #userStateService = inject(UserStateService)
  #loginFlowStateService = inject(LoginFlowStateService)
  #eventService: AppEventService = inject(AppEventService)
  #router = inject(Router)

  #tokenUrl = this.#environmentService.getUrl('oauth/token')
  #sessionUrl = this.#environmentService.getUrl('ng/v1/common/startSession')
  #loginUrl = this.#environmentService.getUrl('ng/v1/user/login/')
  #forgotPasswordUrl = this.#environmentService.getUrl('ng/v1/user/validate/')
  #validatePinUrl = this.#environmentService.getUrl('ng/v1/user/validatepin/')
  #userUpdateUrl = this.#environmentService.getUrl('ng/v1/user/update/')
  #logoutUrl = this.#environmentService.getUrl('ng/v1/user/logout/')
  #userWelcomeUrl = this.#environmentService.getUrl('ng/v1/user/welcome/')

  public async initDevice() {
    return this.#deviceService.initDevice()
  }

  initAuthInformationAndLogin(value: { email: string; pass: string; rememberMe: boolean }) {
    return this.fetchToken()
      .pipe(
        tap((token: Token) => this.#appStateService.setToken(token)),
        delay(500),
        switchMap(() => this.fetchSession()),
        tap((resp) => this.#appStateService.setSession(resp)),
        switchMap(() => this.login(value))
      )
  }



  fetchToken(): Observable<Token> {
    console.log('url', this.#tokenUrl)
    const headers: HttpHeaders = new HttpHeaders({
      'Authorization': `Basic ${this.#environmentService.env.tokenCredentials}`
    })
    return this.#dataService.get<Token>(this.#tokenUrl, headers, true, 'json')
  }

  fetchSession(): Observable<Session> {
    return this.#dataService.get<Session>(this.#sessionUrl)
  }

  login(formData: { email: string; pass: string; rememberMe: boolean }): Observable<UserWelcomeResponse> {
    const sessionId = this.#appStateService.getSession()?.SessionID

    const device = this.#appStateService.getDevice()
    if (!device) {
      return of({} as UserWelcomeResponse)
    }

    return this.#deviceService.getHashForDeviceId(uuid.v4(), formData.email)
      .pipe(switchMap((deviceIdHash) => {
        const payload = this.#buildLoginPayload(formData, device, deviceIdHash)
        const endpoint = this.#loginUrl + sessionId
        const reqHeaders = new HttpHeaders().set('Content-Type', 'application/json')
        return this.#dataService.post<LoginReq, LoginResp>(endpoint, payload, reqHeaders)
      }), switchMap((resp) => {
        const authorized = this.#isAuthorized(resp)
        this.#userStateService.setUser(resp.UserDetails)
        this.#loginFlowStateService.setLoginFlow(resp.LoginFlow)
        return !authorized ? of(null) : this.getWelcomeInfo()
      }),
        map(val => {
          if (val) {
            this.#eventService.raiseEventOf<UserWelcomeResponse>(EVENT_TYPE.PROFILE, val)
          }
          return val
        }))
  }

  getWelcomeInfo(): Observable<UserWelcomeResponse> {
    const sessionId = this.#appStateService.getSession()?.SessionID;
    const endpoint = this.#userWelcomeUrl + sessionId;
    const reqHeaders = new HttpHeaders().set('Content-Type', 'application/json')
    return this.#dataService.get<UserWelcomeResponse>(endpoint, reqHeaders)
      .pipe(tap((resp) => {
        this.#userStateService.setUserWelcome(resp)
        this.#userStateService.setAuthorized(true)
      }))
  }

  saveBasicInformation(formData: { firstName: string; lastName: string, securityQuestion: number, securityAnswer: string }) {
    const sessionId = this.#appStateService.getSession()?.SessionID
    const userId = this.#userStateService.getUnauthorizedUser()?.UserId

    const payload = {
      UserId: userId,
      FirstName: formData.firstName,
      LastName: formData.lastName,
      SecurityQuestionId: formData.securityQuestion,
      SecurityQuestionAnswer: formData.securityAnswer,
    }

    const endpoint = this.#userUpdateUrl + sessionId

    const reqHeaders = new HttpHeaders().set('Content-Type', 'application/json')
    return this.#dataService.put<unknown, unknown>(endpoint, payload, reqHeaders)
  }

  initForgotPassword(formData: { email: string }) {
    return this.fetchToken()
      .pipe(
        tap((token: Token) => {
          this.#appStateService.setToken(token)
        }),
        delay(500),
        switchMap(() => {
          return this.fetchSession()
        }),
        switchMap((resp) => {
          this.#appStateService.setSession(resp)
          return this.forgotPassword(formData)
        })
      )
  }

  forgotPassword(formData: { email: string }): Observable<ForgotPasswordResp> {
    const sessionId = this.#appStateService.getSession()?.SessionID

    const payload: ForgotPasswordReq = {
      Email: formData.email,
      SessionType: SessionTypeEnum.FORGOT_PASSWORD
    }

    const endpoint = this.#forgotPasswordUrl + sessionId

    const reqHeaders = new HttpHeaders().set('Content-Type', 'application/json')
    return this.#dataService.post<ForgotPasswordReq, ForgotPasswordResp>(endpoint, payload, reqHeaders)
  }

  validatePasscode(formData: { pass: number }): Observable<ValidatePasscodeResp> {
    const sessionId = this.#appStateService.getSession()?.SessionID
    const userId = this.#userStateService.getUnauthorizedUser()?.UserId

    const payload: ValidatePasscodeReq = {
      UserId: userId,
      Pin: formData.pass,
      SessionType: SessionTypeEnum.FORGOT_PASSWORD
    }

    const endpoint = this.#validatePinUrl + sessionId

    const reqHeaders = new HttpHeaders().set('Content-Type', 'application/json')
    return this.#dataService.post<ValidatePasscodeReq, ValidatePasscodeResp>(endpoint, payload, reqHeaders)
  }

  createPassword(formData: { pass: string }, SessionType = SessionTypeEnum.FORGOT_PASSWORD): Observable<unknown> {
    const sessionId = this.#appStateService.getSession()?.SessionID
    const userId = this.#userStateService.getUnauthorizedUser()?.UserId

    const payload: CreatePasswordReq = {
      UserId: userId,
      Password: formData.pass,
      SessionType
    }

    const endpoint = this.#userUpdateUrl + sessionId

    const reqHeaders = new HttpHeaders().set('Content-Type', 'application/json')
    return this.#dataService.put<CreatePasswordReq, unknown>(endpoint, payload, reqHeaders)
  }

  logout() {
    const sessionId = this.#appStateService.getSession()?.SessionID
    const endpoint = this.#logoutUrl + sessionId
    return this.#dataService.post(endpoint).
      pipe(finalize(() => this.#resetStateAndNavigate())).subscribe();
  }

  #buildLoginPayload(formData: { email: string; pass: string; rememberMe: boolean }, device: Device, deviceIdHash: string) {
    return {
      Email: formData.email,
      Password: formData.pass,
      DeviceID: deviceIdHash,
      Latitude: device.latitude,
      Longitude: device.longitude,
      BrowserType: '',
      BrowserPlatform: '',
      BrowserIsMobile: false,
      BrowserMobileModel: '',
      BrowserUserAgent: ''
    }
  }

  #resetStateAndNavigate() {
    sessionStorage.removeItem('UserState');
    sessionStorage.removeItem('OrderState');
    sessionStorage.removeItem('LoginFlowState');
    sessionStorage.removeItem('AppState');
    this.#router.navigate(['login']);
    window.location.reload()
  }

  #isAuthorized(resp: LoginResp): boolean {
    return !(resp.LoginFlow.Do2FaCheck || resp.LoginFlow.Do2FaSetup || resp.LoginFlow.DoForcedPasswordReset || resp.LoginFlow.DoSecurityQandA)
  }

}
