<div class="flex flex-column min-h-screen">
    <div class="w-full bg-blue-sara h-12rem flex justify-content-center align-items-center mobile-hide">
        <img src="assets/images/logo/SARAWhiteLogoBorderless.png" alt="Sara Plus Logo" class="w-15rem">
    </div>
    <div class="bg-blue-sara h-4rem flex justify-content-between mobile-show">
        <div class="pt-2 pl-2">
            <img src="assets/images/logo/SARAPlusLogoWhite.png" alt="Sara Plus Logo" class="w-3rem">
        </div>
        <!-- <div class="pt-2 pr-2">
            <i class="pi pi-bars text-5xl"></i>
        </div> -->
    </div>
    <div class="p-2 sm:p-4 flex-1 justify-content-center sm:align-content-center pt-8 sm:pt-0">
        <ng-content></ng-content>
    </div>

    <div class="flex flex-column align-items-center pb-7 justify-content-end w-full h-12rem">
        <p-button [label]="'Terms of Use and Privacy Policy'" [link]="true" (click)="showPivacyTerms()"
            styleClass="font-italic text-lg text-color-secondary"></p-button>
    </div>
    <p-dialog styleClass="w-full md:w-9 lg:w-10 xl:w-9" [(visible)]="privacyTerms">
        <app-privacy-policy></app-privacy-policy>
    </p-dialog>
</div>