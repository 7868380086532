import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http'
import { Injectable, inject } from '@angular/core'
import { Observable, catchError } from 'rxjs'
import { NotificationService } from '../services/notification.service'
import { Message } from '../enums/message.enum'

@Injectable({
  providedIn: 'root'
})
export class HttpErrorInterceptor implements HttpInterceptor {
  notificationService = inject(NotificationService)

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const skipIntercept = request.headers.has('skip');
    if (skipIntercept) {
      request = request.clone({
        headers: request.headers.delete('skip')
      });
    }
    return next.handle(request).pipe(catchError((err: any) => {
      const errorResponse = err as HttpErrorResponse;

      if (err && err.error && JSON.parse(err.error).ErrorDescription) {
        err.error = JSON.parse(err.error).ErrorDescription;
      }
      if (!skipIntercept) {
        if (errorResponse.status === 401 || errorResponse.status === 403) {
          this.notificationService.showError(errorResponse.status === 401 ? Message.NotAuthporized : Message.Forbidden, Message.Error);
        }
        else if (errorResponse.status == 400) {
          this.notificationService.showError(err.error, Message.Error);
        } else {
          this.notificationService.showError(Message.ErrorMessage, Message.Error);
        }
      }
      throw err;
    }))
  }
}