import { Injectable, Signal, computed, inject } from '@angular/core'
import { toSignal } from '@angular/core/rxjs-interop'
import { Select, Store } from '@ngxs/store'
import { Observable } from 'rxjs'
import { LoginFlow } from '../../../models/Login'
import { EncryptDecryptService } from '../../../services/encrypt-decrypt.service'
import { EnvironmentService } from '../../../services/environment.service'
import { LoginFlowAction, LoginFlowState } from './login-flow.state'

@Injectable({
    providedIn: 'root'
})
export class LoginFlowStateService {

    #store: Store = inject(Store)
    #encryptDecryptService: EncryptDecryptService = inject(EncryptDecryptService)
    #environmentService: EnvironmentService = inject(EnvironmentService)

    @Select(LoginFlowState.LoginFlow) private loginFlow$: Observable<string>

    #loginFlow: Signal<string>
    
    constructor() {
        this.#loginFlow = toSignal(this.loginFlow$) as Signal<string>
    }

    setLoginFlow(loginFlow: LoginFlow) {
        this.#store.dispatch(new LoginFlowAction(loginFlow))
    }

    getLoginFlow = computed(() => {
        return this.#encryptDecryptService.decrypt<LoginFlow>(this.#loginFlow(), this.#environmentService.sessionDecryptKey)
    })
   
}