import { Component, OnInit } from '@angular/core';
import { TitleModel } from '../../../services/title.service';
import { BaseComponent } from '../../../components/base.component';

@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html',
  styleUrl: './page-title.component.scss'
})
export class PageTitleComponent extends BaseComponent implements OnInit {

  title: TitleModel;

  ngOnInit(): void {
    this.titleService.listen().subscribe({
      next: (model) => {
        this.onTitleChange(model);
      }
    })
  }

  onTitleChange(model: TitleModel) {
    this.title = model;
  }

}
