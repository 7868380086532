import { Component, OnInit, Renderer2, effect, inject } from '@angular/core'
import { MenuItem } from 'primeng/api'
import { BaseComponent } from '../../components/base.component'
import { AppEventService, EVENT_TYPE } from '../../services/app-event.service'
import { filter } from 'rxjs'
import { UserWelcomeResponse } from '../../models/Login'
import { MenuService } from '../../services/menu.service'
import { AuthService } from '../../services/auth.service'
import { UserStateService } from '../../store/state/user/user.state.service'

@Component({
  selector: 'app-layout',
  templateUrl: './app-layout.component.html',
  styleUrl: './app-layout.component.scss'
})
export class AppLayoutComponent extends BaseComponent implements OnInit {

  #menuService = inject(MenuService)
  #authService = inject(AuthService)
  #userStateService = inject(UserStateService)
  #eventService = inject(AppEventService)
  #renderer = inject(Renderer2)

  menuItems: MenuItem[]
  isLoggedIn: boolean;
  eventService: AppEventService = inject(AppEventService)
  isMenuCollapsed = true;

  constructor() {
    super()
    effect(() => {
      this.isLoggedIn = this.#userStateService.getIsLoggedIn()
      const userWelcomeInfp = this.#userStateService.getUserWelcomeInfo();
      this.#eventService.raiseEventOf<UserWelcomeResponse>(EVENT_TYPE.PROFILE, userWelcomeInfp);
      this.menuItems = this.#menuService.getMenu()
      if (this.isLoggedIn) {
        this.loadJsScript()
      } else {
        this.removeZenDesk()
      }
    })
  }

  ngOnInit(): void {

    this.subscriptions.add(
      this.eventService.dataEvent$
        .pipe(filter(x => x.event == EVENT_TYPE.MENU_COLLAPSED))
        .subscribe({
          next: (data) => {
            this.isMenuCollapsed = data.data as boolean;
          }
        }));

    this.subscriptions.add(this.eventService.dataEvent$
      .pipe(filter(x => x.event == EVENT_TYPE.PROFILE && x.data != undefined)).subscribe({
        next: (data) => {
          (data.data as UserWelcomeResponse).Modules
        }
      }))

    this.subscriptions.add(this.#eventService.event$.subscribe({
      next: (event: string) => {
        if (event === EVENT_TYPE.LOGOUT) {
          this.subscriptions.add(this.#authService.logout())
        }
      }
    }))
  }

  loadJsScript() {
    const node = document.createElement('script')
    node.id = 'ze-snippet'
    node.type = 'text/javascript'
    node.src = 'https://static.zdassets.com/ekr/snippet.js?key=a2b0d83e-e5cf-4e06-bd0f-48ceb8270020'
    this.#renderer.appendChild(document.body, node)
  }

  removeZenDesk(): void {
    const zenDeskNode = document.getElementById('ze-snippet');
    if (zenDeskNode) {
      this.#renderer.removeChild(document.body, zenDeskNode);
    }
  }

}
