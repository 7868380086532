<div>
    <div class="bg-gray-800 p-3 flex flex-column md:flex-row gap-3">
        <div class="flex gap-3 md:mr-4 lg:mr-8">
            <div class="flex md:align-items-start align-items-center md:pt-2">
                <img src="assets/images/logo/footer_logo.png" alt="footer sections" width="40" height="40">
            </div>
            <div class="text-gray-300 font-bold text-5xl">
                SARA Plus
            </div>
        </div>
        <div class="flex-1 flex flex-column gap-3 justify-content-between md:flex-row md:w-full">
            <div class="">
                <div class="text-white text-lg mb-4 flex flex-wrap" style="max-width: 290px;">Sara Plus </div>
                <div class="text-white mb-3"><i class="pi pi-phone surface-800 border-round p-1 mr-2"></i>(+1)
                    999 999 9999</div>
                <div class="text-white mb-3"><i
                        class="pi pi-inbox surface-800 border-round p-1 mr-2"></i>hello&#64;saraplus.com</div>
            </div>
            <div class="text-gray-200">
                <div class="text-white font-bold line-height-3 mb-3">Company</div>
            </div>
            <div class="text-gray-200">
                <div class="text-white font-bold line-height-3 mb-3">Resourses</div>
            </div>
        </div>
    </div>
</div>