import { Component, Input } from '@angular/core';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-authorized-layout',
  templateUrl: './authorized-layout.component.html',
  styleUrl: './authorized-layout.component.scss'
})
export class AuthorizedLayoutComponent {

  logoUrl: string = ''
  @Input() menuItems: MenuItem[]
  @Input() isLoggedIn: boolean;
  @Input() isMenuCollapsed = true;
}
