import { Injectable } from '@angular/core'
import { Observable, Subject } from 'rxjs';

export interface TitleModel {
  title: string;
  subTitle?: string
}
@Injectable({
  providedIn: 'root'
})
export class TitleService {
  private eventSubject = new Subject<TitleModel>()
  private $events = this.eventSubject.asObservable();

  update(model: TitleModel) {
    this.eventSubject.next(model);
  }

  listen(): Observable<TitleModel> {
    return this.eventSubject.asObservable();
  }

}
