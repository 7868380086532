import { Component, Input, inject } from '@angular/core'
import { UserStateService } from '../../../store/state/user/user.state.service'
import { AppEventService, EVENT_TYPE } from '../../../services/app-event.service'

@Component({
  selector: 'app-sticky-header',
  templateUrl: './app-sticky-header.component.html',
  styleUrl: './app-sticky-header.component.scss'
})
export class AppStickyHeaderComponent {
  @Input() logoUrl: string = ''
  userStateService: UserStateService = inject(UserStateService)
  eventService: AppEventService = inject(AppEventService)

  onLogoutClicked() {
    this.eventService.raiseEvent(EVENT_TYPE.LOGOUT)
  }
}
