import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'
export interface MenuChangeEvent {
    key: string
    routeEvent?: boolean
}
@Injectable({
    providedIn: 'root'
})
export class AppMenuService {

    private menuSource = new Subject<MenuChangeEvent>()
    private resetSource = new Subject()

    menuSource$ = this.menuSource.asObservable()
    resetSource$ = this.resetSource.asObservable()

    onMenuStateChange(event: MenuChangeEvent) {
        this.menuSource.next(event)
    }

    reset() {
        this.resetSource.next(true)
    }
}
