import { Injectable, inject } from '@angular/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { ForgotPasswordResp } from '../../../models/ForgotPassword'
import { UserDetails, UserWelcomeResponse } from '../../../models/Login'
import { EncryptDecryptService } from '../../../services/encrypt-decrypt.service'
import { EnvironmentService } from '../../../services/environment.service'

export const USER_STATE_KEY = 'UserState'
export const USER_ACTION_TYPE = {
  SET_USER: `${USER_STATE_KEY}.SET_USER`,
  SET_UNAUTHORIZED_USER: `${USER_STATE_KEY}.SET_UNAUTHORIZED_USER`,
  SET_USER_AUTHORIZED: `${USER_STATE_KEY}.SET_USER_AUTHORIZED`,
  SET_USER_WELCONE_INFO: `${USER_STATE_KEY}.SET_USER_WELCONE_INFO`
}

export interface UserStateModel {
  user?: unknown
  isAuthorized?: boolean,
  welcomeInfo?: unknown
}

export class AddUserAction {
  static readonly type = USER_ACTION_TYPE.SET_USER
  constructor(public userDetails: UserDetails) { }
}

export class AddUnauthorizedUserAction {
  static readonly type = USER_ACTION_TYPE.SET_UNAUTHORIZED_USER
  constructor(public user: ForgotPasswordResp) { }
}

export class UserAuthorizedAction {
  static readonly type = USER_ACTION_TYPE.SET_USER_AUTHORIZED
  constructor(public isAuthorized: boolean) { }
}

export class UserWelcomeInfoAction {
  static readonly type = USER_ACTION_TYPE.SET_USER_WELCONE_INFO
  constructor(public response: UserWelcomeResponse) { }
}

@State<UserStateModel>({
  name: USER_STATE_KEY,
  defaults: {
    user: undefined,
  }
})
@Injectable()
export class UserState {

  encryptDecryptService: EncryptDecryptService = inject(EncryptDecryptService)
  environmentService: EnvironmentService = inject(EnvironmentService)

  @Action(AddUserAction)
  addUser({ patchState }: StateContext<UserStateModel>, { userDetails }: AddUserAction): void {
    const encryptedUserInfo = this.encryptDecryptService.encrypt(userDetails, this.environmentService.sessionDecryptKey)
    patchState({ user: encryptedUserInfo })
  }

  @Action(AddUnauthorizedUserAction)
  addUnauthorizedUser({ patchState }: StateContext<UserStateModel>, { user }: AddUnauthorizedUserAction): void {
    const encryptedUserInfo = this.encryptDecryptService.encrypt(user, this.environmentService.sessionDecryptKey)
    patchState({ user: encryptedUserInfo })
  }

  @Action(UserAuthorizedAction)
  UserAuthorized({ patchState }: StateContext<UserStateModel>, { isAuthorized }: UserAuthorizedAction): void {
    patchState({ isAuthorized: isAuthorized })
  }

  @Action(UserWelcomeInfoAction)
  UserWelcomeInfoAction({ patchState }: StateContext<UserStateModel>, { response }: UserWelcomeInfoAction): void {
    const encryptedUserInfo = this.encryptDecryptService.encrypt(response, this.environmentService.sessionDecryptKey)
    patchState({ welcomeInfo: encryptedUserInfo })
  }

  @Selector([UserState])
  static UserInfo(state: UserStateModel) {
    return state?.user
  }

  @Selector([UserState])
  static UnauthorizedUserInfo(state: UserStateModel) {
    return state?.user
  }

  @Selector([UserState])
  static isAuthorized(state: UserStateModel) {
    return state.isAuthorized
  }

  @Selector([UserState])
  static userWelcomeInfo(state: UserStateModel) {
    return state.welcomeInfo;
  }
}
